<app-subpage-navigator currentPageTitle="Család -és gyermekjóléti központ"></app-subpage-navigator>
<div class="text-container">
  <p style="padding: 0em 4em 0em 4em">
      A Központ szakmai egységének feladata egyrészt a gyermekek családban történő nevelkedésének elősegítése, valamint a gyermekek veszélyeztetettségének megelőzése érdekében speciális szolgáltatások nyújtása, másrészt a gyermekvédelmi gondoskodás keretébe tartozó, hatósági intézkedésekhez kapcsolódó, a gyermekek védelmére irányuló tevékenység ellátása.
  </p>
  <h4>A Központ  szolgáltatásai:</h4>
  <ul>
    <li>óvodai- és iskolai szociális munka</li>
    <li>utcai és lakótelepi szociális munka</li>
    <li>kapcsolattartási ügyeletet</li>
    <li>mediáció (ide nem értve a Gyvt.62/E. § és a 132. § (6) bekezdése szerinti közvetítői eljárást)</li>
    <li>gyermekvédelmi jelzőrendszeri készenléti szolgálat biztosítása</li>
    <li>jogi tájékoztatás nyújtása</li>
    <li>pszichológiai tanácsadás</li>
    <li>fejlesztőpedagógiai tanácsadás</li>
    <li>szociális diagnózis</li>
    <li>családterápiás szolgáltatás</li>
  </ul>

  <h3>Óvodai- és iskolai szociális segítő munka</h3>
  <p class="text-indent">A szolgáltatás célja a szociális segítő szolgáltatás nyújtása köznevelési intézményekben. A gyermek veszélyeztetettségének megelőzése érdekében a szociális segítő munka eszközeivel támogatást nyújt a köznevelési intézménybe járó gyermeknek, a gyermek családjának és a köznevelési intézmény pedagógusainak egyéni, csoportos és közösségi szociális munka keretében </p>
  
  <h3>Utcai és lakótelepi szociális munka </h3>
  <p class="text-indent">
      A szolgáltatás célja a magatartásával testi, lelki, értelmi fejlődését veszélyeztető, a szabadidejét az utcán töltő kallódó, csellengő gyermek speciális segítése, illetve a lakóhelyéről önkényesen eltávozó, vagy gondozója által a lakásból kitett, ellátás nélkül maradó gyermek felkutatása, lakóhelyére történő visszakerülésének elősegítése, szükség esetén átmeneti gondozásának, vagy gyermekvédelmi gondoskodásban részesítésének kezdeményezése. 
  </p>
  <p class="text-indent">
      A szociális munkás az érintett gyermekek lakókörnyezetében, az általuk látogatott bevásárlóközpontokban, vagy a saját szolgáltatási körében szervezi a gyermekeknek szóló, beilleszkedésüket, szabad idejük hasznos eltöltését segítő programokat, melyekbe lehetőség szerint a gyermek családját is bevonja. 
  </p>

  <h3>Kapcsolattartási ügyelet </h3>
  <p class="text-indent">
      A kapcsolattartási ügyelet a gyermek és a szülő, valamint a más kapcsolattartásra jogosult közeli hozzátartozó közötti kapcsolatot segítő speciális szolgáltatás. Semleges helyet, barátságos légkört, igény esetén szakmai tanácsadást biztosít a szülőknek nevelési gondjaik megoldásában.
  </p>
  <p class="text-indent">
      A kapcsolattartási ügyelethez fordulókkal történő előkészítő beszélgetések után, az ügyeleti mediáció során a szülők és a gyermekek kapcsolattartási megállapodást kötnek. A megállapodás betartásáról rendszeresen konzultálnak a résztvevőkkel. Az ügyelet munkatársai speciálisan képzett szakemberek.
  </p>

  <h3>Mediáció</h3>
  <p class="text-indent">
      A mediáció a konfliktuskezelés egyik lehetséges módszere, amelyet a felek önkéntesen vesznek igénybe. A mediáció az a folyamat, amelynek során független szakember, ún. mediátor (közvetítő, közbenjáró, speciálisan képzett szakember) segíti a feleket a kölcsönösen elfogadható egyezség, kompromisszum elérésében. A mediáció, azaz közvetítés, egy lehetséges megoldási mód akkor, ha az érintettek, bár akarnak, de egymás közt közvetlenül már nem tudnak megegyezni.
  </p>
  <p class="text-indent">
      A mediáció meghatározott, a felek által előzetesen elfogadott szabályok szerint zajlik, a jövőben elérendő célok megfogalmazására, majd azok megvalósítására irányul. A mediátor segíti a feleket ebben, ösztönzi őket arra, hogy a megoldás érdekében minél több lehetőséget kutassanak fel, vegyenek számba. Mindezek figyelembevételével, egyeztetésével születik meg a megállapodás.
  </p>
  <p class="text-indent">
      A folyamat során a gyermek érdekeit a mediátor képviseli és védi.
  </p>

  <p class="text-indent">
    Mediációval a következő konfliktusok rendezésére van lehetőség:
  </p>
  <ul>
    <li>szülő-serdülő konfliktus</li>
    <li>párkapcsolati nehézségek</li>
    <li>válás vagy szakítás során felmerülő gyermek elhelyezési ügyek</li>
    <li>kapcsolattartási ügyek</li>
  </ul>

  <h3>Gyermekvédelmi jelzőrendszeri készenléti szolgálat</h3>
  <p class="text-indent">
      Az Intézmény a gyermekvédelmi jelzőrendszeri készenléti szolgálatot állandóan hívható telefonszámmal biztosítja. A készenlétben lévő munkatárs tanácsadással, tájékoztatással, segítség mozgósításával szakszerű segítséget nyújt.
  </p>
  <p class="text-indent">
      A gyermekvédelmi jelzőrendszeri készenléti szolgálat az év minden napján az Intézmény nyitva tartási idején túl, éjszaka, hétvégén és ünnepnapokon, egyéb munkaszüneti napokon folyamatosan biztosítja a szakember telefonos elérhetőségét abból a célból, hogy krízishelyzet esetén a gyermek, felnőtt ne maradjon segítség nélkül. A szakember helyszínre nem megy.
  </p>

  <h3>Szociális diagnózis</h3>
  <p class="text-indent">
      A család- és gyermekjóléti központ a család szociális helyzetének átfogó vizsgálata alapján elkészíti az igénybe venni javasolt szociális szolgáltatásokat meghatározó szociális diagnózist. A család- és gyermekjóléti központ a szociális diagnózis elkészítésébe bevonhatja a család- és gyermekjóléti szolgálatokat. A jogosultsági feltételek fennállása esetén a szociális diagnózis kötelezi a szociális szolgáltatókat, intézményeket.
  </p>

  <h3>Jogi tájékoztatás nyújtása</h3>
  <p class="text-indent">
      A szolgáltatás havi rendszerességgel biztosít jogi tanácsadást a hozzá fordulók részére.
  </p>

  <h3>Pszichológiai tanácsadás</h3>
  <p class="text-indent">
      A család életében előforduló, biztonságérzetüket megingató nehézségek, krízishelyzetek gyakran jelentenek megoldhatatlannak tűnő problémát a családtagok számára, ezekben az esetekben nyújt segítséget a pszichológus.
  </p>
  <p class="text-indent">
      A pszichológus a klienssel történő első beszélgetést követően elvégzi az ilyenkor szükséges pszichológiai vizsgálatokat, majd tanácsadással segíti az ügyfeleket (a szülőt a gyermeknevelésben, a gyermeket problémái megoldásában, a felnőtt klienseket az adott konfliktusos élethelyzet rendezésében). Amennyiben szükséges, rövid szuportív terápiás munkát végez. . Konzultációt biztosít a családgondozók és esetmenedzserek részére.
  </p>

  <h3>Fejlesztőpedagógiai tanácsadás</h3>
  <p class="text-indent">
      A fejlesztőpedagógus otthoni körülmények között is megvalósítható ötleteket, tanácsokat, esetleg hozzáférhető szakirodalmat ajánl a felmerülő tanulási problémák kezelése érdekében. Korrepetálást végez egyéni és csoport keretek között. Konzultációt biztosít a családgondozók és esetmenedzserek részére.
  </p>

  <h3>Családterápiás szolgáltatás</h3>
  <p class="text-indent">
      A családterapeuta  családterápiás, párterápiás, válásterápiás és gyászterápiás szolgáltatást nyújt.
  </p>

  <br />

  <h3>A Központ gyermekvédelmi gondoskodás keretébe tartozó, hatósági intézkedésekhez köthető feladatai</h3>
  <ul>
    <li>kezdeményezi a gyermek védelembe vételét, súlyosabb fokú veszélyeztetettség esetén a gyermek ideiglenes hatályú elhelyezését, nevelésbe vételét</li>
    <li>javaslatot készít a veszélyeztetettség mértékének megfelelően a gyermek védelembe vételére, illetve a családi pótlék természetbeni formában történő nyújtására, a gyermek tankötelezettsége teljesítésének előmozdítására, a gyermek családjából történő kiemelésére, a leendő gondozási helyére vagy annak megváltoztatására, valamint a gyermek megelőző pártfogásának mellőzésére, elrendelésére, fenntartására és megszüntetésére</li>
    <li>együttműködik a pártfogó felügyelői szolgálattal és a megelőző pártfogó felügyelővel a bűnismétlés megelőzése érdekében, ha a gyámhatóság elrendelte a gyermek megelőző pártfogását</li>
    <li>válsághelyzetben lévő várandós anya támogatása, segítése, tanácsokkal való ellátása, valamint szociális szolgáltatásokhoz és gyermekjóléti alapellátásokhoz, különösen a családok átmeneti otthonában igénybe vehető ellátáshoz történő hozzájutásának szervezése</li>
    <li>a családjából kiemelt gyermek visszahelyezése érdekében családgondozást végez – az otthont nyújtó ellátást, illetve a területi gyermekvédelmi szakszolgáltatást végző intézménnyel együttműködve – a család gyermeknevelési körülményeinek megteremtéséhez, javításához, a szülő és a gyermek közötti kapcsolat helyreállításához</li>
    <li>utógondozást végez – az otthont nyújtó ellátást, illetve a területi gyermekvédelmi szakszolgáltatást végző intézménnyel együttműködve – a gyermek családjába történő visszailleszkedéséhez</li>
    <li>védelembe vett gyermek esetében elkészíti a gondozási-nevelési tervet, koordinálja a szakemberek együttműködését az esetkezelés során</li>
  </ul>
</div>