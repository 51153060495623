import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gyejo-kozpont-ismerteto',
  templateUrl: './gyejo-kozpont-ismerteto.component.html',
  styleUrls: ['./gyejo-kozpont-ismerteto.component.css']
})
export class GyejoKozpontIsmertetoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
