import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-statusz-sor',
  templateUrl: './statusz-sor.component.html',
  styleUrls: ['./statusz-sor.component.css']
})
export class StatuszSorComponent implements OnInit {

  @Input() title: string = "Aktuális oldal címe";

  constructor() { }

  ngOnInit() {
  }

}
