import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-szoc-szolg-ismerteto',
  templateUrl: './szoc-szolg-ismerteto.component.html',
  styleUrls: ['./szoc-szolg-ismerteto.component.css']
})
export class SzocSzolgIsmertetoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
