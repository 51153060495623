<app-subpage-navigator currentPageTitle="Szociális szolgálat"></app-subpage-navigator>
<div class="text-container">
  <h3>A Szociális Szolgálat feladatai:</h3>
  <ul>
    <li>szociális étkeztetés biztosítása</li>
    <li>házi segítségnyújtás biztosítása</li>
    <li>jelzőrendszeres segítségnyújtás biztosítása</li>
  </ul>

  <h3>Szociális étkeztetés</h3>
  <p class="text-indent">
      Az étkeztetés keretében a Szolgálat azoknak a szociálisan rászorulóknak a legalább napi egyszeri meleg étkezéséről gondoskodik, akik azt önmaguknak, illetve önmaguk és eltartottjaik részére tartósan vagy átmeneti jelleggel nem képesek biztosítani. Az ellátást igénybevevőknek diétás étkezést is tudunk biztosítani szakorvos javaslatára.
  </p>
  <p class="text-indent">Dabas településen lehetőség van házhozszállításra, elvitelre vagy helyben fogyasztásra</p>
  <p class="text-indent">Inárcs településen lehetőség van házhozszállításra vagy elvitelre</p>
  <p class="text-indent">Kakucs településen  lehetőség van házhozszállításra vagy elvitelre</p>
  <p class="text-indent">Újhartyán településen házhozszállítással nyújtunk szolgáltatást</p>
  <p class="text-indent">Újlengyel településen  lehetőség van házhozszállításra vagy elvitelre</p>
  <p class="text-indent">Pusztavacs településen házhozszállítással nyújtunk szolgáltatást</p>

  <h3>Házi segítségnyújtás</h3>
  <p class="text-indent">
    A házi segítségnyújtás olyan szolgáltatás, amely az igénybe  vevő önálló életvitelének fenntartását szükségleteinek megfelelően – lakásán, lakókörnyezetében biztosítja.
  </p>

  <p class="text-indent">A házi segítségnyújtás:</p>
  <ul>
    <li>a <span class="bold-mark">személyi gondozás</span> keretében gondozási és háztartási segítségnyújtás szolgáltatási elemet biztosít</li>
    <li>a <span class="bold-mark">szociális segítés</span> keretében háztartási segítségnyújtás szolgáltatási elemet biztosít</li>
  </ul>

  <h3>Jelzőrendszeres házi segítségnyújtás</h3>
  <p class="text-indent">
      A szolgáltatás célja az ellátási területen élő rászoruló lakosok részére 24 órás távfelügyelet biztosítása. A jelzőkészülékkel rendelkező ellátott krízishelyzet esetén, a lakásán elhelyezett készülékkel jelez a diszpécserközpontba. A diszpécser a beérkezett jelzést a lehető legrövidebb időn belül továbbítja az ügyeletes gondozóhoz, aki 30 percen belül kimegy a gondozotthoz és gondoskodik az ellátásról, a krízishelyzet megszüntetéséről. A szociális gondozó a helyszínen kitölti a segélyhívási jegyzőkönyvet.
  </p>
</div>