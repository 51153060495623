<div class="menu-sav">
  <ul class="nav">
      <li>
        <a href="#alt_info">Általános információk</a>
        <ul>
          <li><a routerLink="ismerteto" >Intézmény ismertető</a></li>
          <div class="h-separator"></div>
          <li><a routerLink="szoc_szolg_imerteto">Szociális szolgálat</a></li>
          <div class="h-separator"></div>
          <li><a routerLink="koz_szolg_imerteto">Közösségi szolgálat</a></li>
          <div class="h-separator"></div>
          <li><a routerLink="gyejo_szolg_imerteto">Család -és gyermekjóléti szolgálat</a></li>
          <div class="h-separator"></div>
          <li><a routerLink="gyejo_kozpont_imerteto">Gyermekjóléti központ</a></li>
        </ul>
      </li>
      <li><a routerLink="adatvedelem">Adatvédelem</a></li>
      <li>
      <li>
        <a href="#telepek">Telephelyek</a>
        <ul>
          <li><a href="#dabas">Dabas</a></li>
          <div class="h-separator"></div>
          <li><a href="#inarcs">Inárcs</a></li>
          <div class="h-separator"></div>
          <li><a href="#kakucs">Kakucs</a></li>
          <div class="h-separator"></div>
          <li><a href="#ujhartyan">Újhartyán</a></li>
          <div class="h-separator"></div>
          <li><a href="#ujlengyel">Újlengyel</a></li>
          <div class="h-separator"></div>
          <li><a href="#pusztavacs">Pusztavacs</a></li>  
        </ul>
      </li>
      <li><a href="#programok">Programok</a></li>
      <li>
        <a href="#dokumentumok">Dokumentumok</a>
        <ul>
            <li><a href="../../../assets/SZMSZ_2022.doc">SZMSZ</a></li>
            <div class="h-separator"></div>
            <li><a href="../../../assets/SZMSZ Organogramm.doc">Szervezeti felépítés</a></li>
            <div class="h-separator"></div>
            <li><a href="../../../assets/Házirend szociális alapellátás 2019.doc">Házirend (alapellátás)</a></li>
            <div class="h-separator"></div>
            <li><a href="../../../assets/szakmai program2022.doc">Szakmai program</a></li>
            <div class="h-separator"></div>
            <li><a href="#nyomtatvanyok">Kérelem nyomtatványok</a></li>
            <div class="h-separator"></div>
            <li><a routerLink="gazd_adatok">Gazdálkodási adatok</a></li>
        </ul>
      </li>
    <li><a routerLink="elerhetosegek">Elérhetőségek</a></li>
  </ul>
</div>