<app-subpage-navigator currentPageTitle="Intézmény ismertető"></app-subpage-navigator>
<div class="text-container">

      <h3>Az Intézmény alapadatai</h3>
      <h4>Intézmény neve:</h4>Dabasi Család- és Gyermekjóléti Szolgálat és Központ
      <p></p>
      <h4>Székhelye:</h4>2373 Dabas, Áchim u. 6.
      <p></p>
      <h4>Területi irodái:</h4>
      <ul>
        <li>Inárcs ( 2365 Inárcs, Ady Endre út 1/B.)</li>
        <li>Kakucs ( 2366 Kakucs, Fő u. 20.)</li>
        <li>Újhartyán ( 2367 Újhartyán, Fő u. 21.)</li>
        <li>Újlengyel ( 2724 Újlengyel, Kossuth  L. út 69.)</li>
        <li>Pusztavacs ( 2378 Pusztavacs, Béke tér 10.)</li>
      </ul>
      
      <br />

      <h3 name="I02">Az Intézmény jogállása és gazdálkodási jogköre</h3>
      <p class="text-indent">Az Intézmény önálló jogi személyiséggel rendelkező, közszolgáltatást nyújtó közintézmény, melyet az alapító és fenntartó által kinevezett egyszemélyi felelős vezető, az intézményvezető vezet.</p>
      <p class="text-indent">Az Intézmény önállóan működő, gazdasági szervezettel nem rendelkező költségvetési szerv.</p>
      
      <br />

      <h3 name="I03">Az Intézmény illetékességi területe</h3>
      <p class="text-indent">Az Intézmény illetékességi területe az alábbi települési önkormányzatok közigazgatási területére terjed ki:</p>
      <ul>
        <li>Dabas Város</li>
        <li>Inárcs Nagyközség</li>
        <li>Kakucs Község</li>
        <li>Újhartyán Város</li>
        <li>Újlengyel Község</li>
        <li>Pusztavacs Község</li>
        <li>Hernád Nagyközség (Gyermekjóléti Központ)</li>
        <li>Örkény Város (Gyermekjóléti Központ)</li>
        <li>Bugyi Nagyközség (Gyermekjóléti Központ)</li>
        <li>Tatárszentgyörgy Község (Gyermekjóléti Központ)</li>
        <li>Táborfalva Nagyközség (Gyermekjóléti Központ)</li>
      </ul>

      <br />

      <h2>AZ INTÉZMÉNY FELADATAI</h2>

      <h3>Az Intézmény közfeladata</h3>
      <span>Az Intézmény közfeladata: szociális, család- és gyermekjóléti alapszolgáltatás </span>
      <p></p>
      <h4>Alaptevékenységek:</h4>
      <ul>
        <li>Család- és gyermekjóléti szolgáltatások </li>
        <li>Család- és gyermekjóléti központ </li>
        <li>Pszichiátriai betegek közösségi alapellátása </li>
        <li>Szociális étkeztetés </li>
        <li>Házi segítségnyújtás </li>
        <li>Jelzőrendszeres házi segítségnyújtás</li>
      </ul>
      <h4>Szociális alapellátások</h4>
      <ul>
        <li>Étkeztetés</li>
        <li>Házi segítségnyújtás</li>
        <li>Pszichiátriai betegek közösségi ellátás</li>
        <li>Jelzőrendszeres házi segítségnyújtás</li>
      </ul>
      <h4>Család- és gyermekjóléti alapellátások</h4>
      <ul>
        <li>Családsegítés</li>
        <li>Gyermekjóléti szolgáltatás</li>
        <li>Gyermekjóléti központ</li>
        <li>Szociális diagnózis</li>
        <li>Utcai szociális munka</li>
        <li>óvodai és iskolai szociális munka</li>
      </ul>

      <br />

      <h3>Az Intézmény tevékenységét meghatározó jogszabályok</h3>
      <ul style="padding-left: 5em">
        <li>a szociális igazgatásról és szociális ellátásokról szóló 1993. évi III. törvény (továbbiakban: Szt.), </li>
        <li>a személyes gondoskodást nyújtó szociális ellátások igénybevételéről szóló 9/1999. (XI. 24.) SzCsM rendelet,</li>
        <li>a személyes gondoskodást nyújtó szociális ellátások térítési díjáról szóló 29/1993. (Il. 17.) Korm. rendelet,</li>
        <li>a gyermekek védelméről és a gyámügyi igazgatásról szóló 1997. évi XXXI. törvény (továbbiakban: Gyvt.),</li>
        <li>a személyes gondoskodást nyújtó szociális intézmények szakmai feladatairól és működésük feltételeiről szóló 1/2000. (I. 7.) SZCSM rendelet,</li>
        <li>a személyes gondoskodást nyújtó gyermekjóléti alapellátások és gyermekvédelmi szakellátások térítési díjáról és az igénylésükhöz felhasználható bizonyítékokról szóló 328/2011. (XII. 29.) Korm. rendelet,</li>
        <li>a gyámhatóságokról, valamint a gyermekvédelmi és gyámügyi eljárásról szóló 149/1997. (IX. 10.) Korm. rendelet,</li>
        <li>a gyámhatóságok, a területi gyermekvédelmi szakszolgálatok, a gyermekjóléti szolgálatok és a személyes gondoskodást nyújtó szervek és személyek által kezelt személyes adatokról szóló 235/1997. (XII. 17.) Korm. rendelet,</li>
        <li>a személyes gondoskodást nyújtó gyermekjóléti, gyermekvédelmi intézmények, valamint személyek szakmai feladatairól és működésük feltételeiről szóló 15/1998. (IV. 30) NM rendelet,</li>
        <li>a szociális, gyermekjóléti és gyermekvédelmi szolgáltatók, intézmények és az országos jelentési rendszerről szóló 415/2015. (XII. 23.) Korm. rendelet,</li>
        <li>a szociális, gyermekjóléti és gyermekvédelmi szolgáltatók, intézmények és hálózatok hatósági nyilvántartásáról és ellenőrzéséről szóló 369/2013. (X.24.) Korm. rendelet,</li>
        <li>a gyermekvédelmi és gyámügyi feladat- és hatáskörök ellátásáról, valamint a gyámhatóság szervezetéről és illetékességéről szóló 331/2006. (XII. 23.) Korm. rendelet,</li>
        <li>az államháztartásról szóló 2011. évi CXCV. törvény,</li>
        <li>az államháztartásról szóló törvény végrehajtásáról szóló 368/2011. (XII. 31 Korm. rendelet,</li>
        <li>a számvitelről szóló 2000. évi C. törvény,</li>
        <li>a közalkalmazottak jogállásáról szóló 1992. évi XXXIII. törvény,</li>
        <li>a közalkalmazottak jogállásáról szóló 1992. évi XXXIII. törvénynek a szociális, valamint a gyermekjóléti és gyermekvédelmi ágazatban történő végrehajtásáról szóló 257/2000. (XII. 26.) Korm. rendelet,</li>
        <li>a személyes gondoskodást végző személyek továbbképzéséről és a szociális szakvizsgáról szóló 9/2000. (VIII.2.) SZCSM. rendelet,</li>
        <li>a munka törvénykönyvéről szóló 2012. évi I. törvény, </li>
        <li>az önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény,</li>
        <li>a közfeladatot ellátó szervek iratkezelésének általános követelményeiről szóló 335/2005. (XII. 29.) Korm. rendelet,</li>
        <li>az egészségügyi és a hozzájuk kapcsolódó személyes adatok kezeléséről és védelméről szóló 1997. évi XLVII. törvény,</li>
        <li>az Intézmény illetékességi területéhez tartozó önkormányzatok hatályos szociális rendeletei</li>
      </ul>
</div>
