import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Location } from '@angular/common';
import { FejlecComponent } from './components/fejlec/fejlec.component';
import { MenuComponent } from './components/menu/menu.component';
import { StatuszSorComponent } from './components/statusz-sor/statusz-sor.component';
import { AktualitasokComponent } from './components/aktualitasok/aktualitasok.component';
import { LablecComponent } from './components/lablec/lablec.component';
import { IsmertetoComponent } from './components/ismerteto/ismerteto.component';
import { ElerhetosegekComponent } from './components/elerhetosegek/elerhetosegek.component';
import { SubpageNavigatorComponent } from './components/subpage-navigator/subpage-navigator.component';
import { GyejoSzolgIsmertetoComponent } from './components/gyejo-szolg-ismerteto/gyejo-szolg-ismerteto.component';
import { GyejoKozpontIsmertetoComponent } from './components/gyejo-kozpont-ismerteto/gyejo-kozpont-ismerteto.component';
import { SzocSzolgIsmertetoComponent } from './components/szoc-szolg-ismerteto/szoc-szolg-ismerteto.component';
import { KozSzolgIsmertetoComponent } from './components/koz-szolg-ismerteto/koz-szolg-ismerteto.component';
import { AdatvedelemComponent } from './components/adatvedelem/adatvedelem.component';
import { GazdAdatokComponent } from './components/gazd-adatok/gazd-adatok.component';

@NgModule({
  declarations: [
    AppComponent,
    FejlecComponent,
    MenuComponent,
    StatuszSorComponent,
    AktualitasokComponent,
    LablecComponent,
    IsmertetoComponent,
    ElerhetosegekComponent,
    SubpageNavigatorComponent,
    GyejoSzolgIsmertetoComponent,
    GyejoKozpontIsmertetoComponent,
    SzocSzolgIsmertetoComponent,
    KozSzolgIsmertetoComponent,
    AdatvedelemComponent,
    GazdAdatokComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
