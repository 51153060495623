import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Scroll, NavigationEnd, NavigationStart, Event as NavigationEvent } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-adatvedelem',
  templateUrl: './adatvedelem.component.html',
  styleUrls: ['./adatvedelem.component.css']
})
export class AdatvedelemComponent implements OnInit {
  
  private fragment: String;

  constructor(private route: ActivatedRoute, private router: Router, private location: Location) {

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => { 
      const tree = router.parseUrl(router.url);
      if (tree.fragment) {
        const element = document.querySelector('#' + tree.fragment);
        if (element) element.scrollIntoView();
      }
      if (event.urlAfterRedirects == '/adatvedelem') document.getElementById("fejlec").scrollIntoView(); 
    });
   }

  ngOnInit() {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    
  }
}
