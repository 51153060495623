<app-subpage-navigator currentPageTitle="Elérhetőségek"></app-subpage-navigator>
<div class="text-container">

    <h3>Az Intézmény székhelyének elérhetősége, nyitva tartása, ügyfélfogadási ideje</h3>

    <h4>Az Intézmény elérhetősége:</h4>
    <ul>
        <li>telefonszáma: 29/367-236</li>
        <li>e-mail címe: segitoszolgalat@dabasicsaladsegito.hu</li>
    </ul>
    <p></p>
    <h4>Az Intézmény nyitva tartása:</h4>
    <ul>
      <li>Hétfőtől - Csütörtökig: 9:00-tól – 16:00-ig</li>
      <li>Pénteken: 9:00-tól – 12:00-ig</li>
    </ul>
    <h4>Az Intézmény ügyfélfogadási ideje:</h4>
    <ul>
      <li>Hétfő: 12:30-tól 16:00-ig</li>
      <li>Keddtől – Csütörtökig: 9:00-tól 12:00-ig és 12:30-tól 16:00-ig</li>
      <li>Pénteken:  9:00-tól 12:00-ig</li>
    </ul>

    <br />

    <h3>Az Intézmény területi irodáinak elérhetősége</h3>

    <h4>Inárcs:</h4>
    <p></p>
    <ul>
      <li>telefonszáma: 20/596-9340</li>
      <li>e-mail címe: segitoszolgalat@dabasicsaladsegito.hu</li>
    </ul>

    <h4>Kakucs:</h4>
    <ul>
      <li>telefonszáma: 20/471-2909</li>
      <li>e-mail címe: segitoszolgalat@dabasicsaladsegito.hu</li>
    </ul>

    <h4>Újhartyán:</h4>
    <ul>
      <li>telefonszáma: 20/471-2909</li>
      <li>e-mail címe: segitoszolgalat@dabasicsaladsegito.hu</li>
    </ul>

    <h4>Újlengyel:</h4>
    <ul>
      <li>telefonszáma: 30/471-3100</li>
      <li>e-mail címe: segitoszolgalat@dabasicsaladsegito.hu</li>
    </ul>

    <h4>Pusztavacs:</h4>
    <ul>
      <li>telefonszáma: 30/471-3100</li>
      <li>e-mail címe: segitoszolgalat@dabasicsaladsegito.hu</li>
    </ul>

    <br />

    <h3>Készenléti Szolgálat elérhetősége, telefonszáma:</h3>
      <h4>minden nap: 0-24 óra  Telefon: 70/337-6337</h4>

</div>