import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gazd-adatok',
  templateUrl: './gazd-adatok.component.html',
  styleUrls: ['./gazd-adatok.component.css']
})
export class GazdAdatokComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
