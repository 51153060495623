import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ismerteto',
  templateUrl: './ismerteto.component.html',
  styleUrls: ['./ismerteto.component.css']
})
export class IsmertetoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
