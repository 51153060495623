<app-subpage-navigator currentPageTitle="Adatvédelem"></app-subpage-navigator>
<div class="text-container">
  <h2 id="teteje">Adatvédelmi tájékoztatás</h2>
  <p><a routerLink="./" fragment="adatvedelmi-elerhetoseg">Adatvédelmi tisztviselő elérhetőségei</a></p>
  <p><a routerLink="./" fragment="adatkezelesi-tajekoztatas">Adatkezelési tájékoztatás</a></p>
  <ul>
    <li><a routerLink="./" fragment="adatkezelo-adatai" >Az adatkezelő adatai</a></li>
    <li><a routerLink="./" fragment="erintett-jogai" >Az érintett jogai</a></li>
    <ul>
        <li><a routerLink="./" fragment="keres-jog" >A tájékoztatás kéréshez való jog</a></li>
        <li><a routerLink="./" fragment="helyesbites-jog" >A helyesbítéshez való jog</a></li>
        <li><a routerLink="./" fragment="torles-jog" >A törléshez való jog</a></li>
        <li><a routerLink="./" fragment="zarolas-jog" >A zároláshoz (adatkezelés korlátozásához) való jog</a></li>
        <li><a routerLink="./" fragment="tiltakozas-jog" >A tiltakozáshoz való jog</a></li>
        <li><a routerLink="./" fragment="adathord-jog" >Az adathordozhatósághoz való jog</a></li>
        <li><a routerLink="./" fragment="hozzajarulas-jog" >Hozzájárulás visszavonásának joga</a></li>
        <li><a routerLink="./" fragment="elszamoltat-jog" >Elszámoltathatósághoz való jog</a></li>
    </ul>
    <li><a routerLink="./" fragment="jogorvoslat">Jogorvoslati lehetőségek</a></li>
    <li><a routerLink="./" fragment="adatkez-szabalyok" >Az intézmény adatkezelését érintő szabályok listája</a></li>  
  </ul>
  <p><a routerLink="./" fragment="int-szabalyozok" >Intézményi szabályozók</a></p>
  <p><a routerLink="./" fragment="adatbiztonsagi-intezk-osszefogl">Adatbiztonsági intézkedések összefoglalása</a></p>
  <ul>
    <li><a routerLink="./" fragment="adatbiztonsagi-alapelvek" >Adatbiztonság alapelvei</a></li>
    <li><a routerLink="./" fragment="beleptetes-megfigyeles" >Beléptetés és elektronikus megfigyelés</a></li>
  </ul>
  <h3 id="adatvedelmi-elerhetoseg">Adatvédelmi tisztviselő elérhetőségei</h3>
    <strong>Adatvédelmi tisztviselő neve:</strong>dr. Anda Péter
    <br/>
    <strong>Adatvédelmi tisztviselő elérhetősége:</strong>dr.anda.peter@gmail.com, 06702762663
    <br/>
    <strong>Adatvédelmi tisztviselő ügyfélfogadása:</strong>Előzetes egyeztetés alapján 
    <br/>
  <h3 id="adatkezelesi-tajekoztatas">Adatkezelési tájékoztatás</h3>
    <strong id="adatkezelo-adatai">Adatkezelő adatai</strong>
      <br/>
      <br/>
        <ul>
          <strong style="width: 16em">Név:</strong>Dabasi Család- és Gyermekjóléti Szolgálat és Központ
          <br/>
          <strong style="width: 16em">Cím:</strong>2373 Dabas, Áchim u. 6.
          <br/>
          <strong style="width: 16em">Telefon:</strong>06-29/367-236
          <br/>
          <strong style="width: 16em">Email:</strong><a href="mailto:segitoszolgalat@gmail.com">segitoszolgalat@gmail.com</a>
          <br/>
          <strong style="width: 16em">Weboldal:</strong>http://www.dabascss.nhely.hu
        </ul>
    <strong id="erintett-jogai">Az érintett jogai</strong>
    <ul>
      <strong id="keres-jog">A tájékoztatás kéréshez való jog</strong>
    </ul>
    <ul>
      Az  érintett  személy  az adatkezelő  elérhetőségein  keresztül,  írásban  tájékoztatást kérhet az Intézménytől arról, hogy
      <ul>
        <li>milyen személyes adatait, </li>
        <li>milyen jogalapon, </li>
        <li>milyen adatkezelési cél miatt, </li>
        <li>milyen forrásból, </li>
        <li>mennyi ideig kezeli, </li>
        <li>az Intézmény  kinek,  mikor,  milyen  jogszabály  alapján,  mely  személyes  adataihoz  biztosított hozzáférést vagy kinek továbbította a személyes adatait. </li>
      </ul>
    </ul>
    <ul>
      <p>Az Intézmény az érintett kérelmét a kérelem benyújtásától számított legrövidebb idő alatt, legfeljebb azonban 25 napon belül az általa megadott elérhetőségre küldött levélben teljesíti. </p>
      <p>A tájékoztatás ingyenes, ha a tájékoztatást kérő a folyó évben azonos adatkörre vonatkozóan tájékoztatási kérelmet az adatkezelőhöz még nem nyújtott be.</p>
      <p>A szolgáltatást igénybevevőnek a rá vonatkozó iratokba iratbetekintési joga van, az iratról másolatot, kivonatot készíthet vagy másolatot kérhet a szolgáltató (intézmény) vezetőjétől.</p>
      <p>Az iratbetekintéshez kapcsolódó adatkezelési tájékoztató <a href="../../../assets/Adatkezelési tájékoztató iratbetekintési jog gyakorlásához (6AM).pdf">itt</a> érhető el.</p>
      <p>Az iratbetekintési formanyomtatvány <a href="../../../assets/Formanyomtatvány iratbetekintési jog gyakorlásához (6M).pdf">itt</a> érhető el.</p>
      <p>A gyermek szülője vagy törvényes képviselője a szolgáltató (intézmény) vezetőjénél kérelmezheti, hogy betekinthessen a külön jogszabály szerinti gyermekvédelmi nyilvántartásnak a gyermek vonatkozásában kitöltött adatlapjaiba, valamint a gyermekjóléti, gyermekvédelmi szolgáltatónál, intézménynél keletkezett, illetve részére megküldött, a gyermekkel kapcsolatos iratba.</p>
      <p>Megtagadható a szülő tájékoztatása, illetve korlátozható a szülő iratbetekintési joga, ha:</p>
      <ul>
          <li>a szülő ellen gyermeke vagy a gyermeket nevelő másik szülő sérelmére elkövetett bűncselekmény miatt büntetőeljárás van folyamatban, annak jogerős befejezéséig,</li>
          <li>a szülő ellen gyermeke vagy a gyermeket nevelő másik szülő sérelmére elkövetett külön törvényben meghatározott hozzátartozók közötti erőszak miatt alkalmazható ideiglenes megelőző távoltartó határozat vagy megelőző távoltartó határozat iránti eljárás van folyamatban, a távoltartás időtartamáig./Gyvt. 136.§/</li>
      </ul>
      <p>Az iratokról kivonat vagy másolat kérhető. A közigazgatási hatósági eljárás általános szabályairól szóló törvényben meghatározottakon túl az érintett írásbeli hozzájárulása hiányában nem lehet betekinteni a másik szülőre vonatkozó, különleges adatot tartalmazó iratba, kivéve, ha az a gyermek érdekében kezdeményezett, a gyermek védelembe vételére vagy nevelésbe vételére irányuló gyámhatósági eljárás, illetve a gyermek elhelyezésének megváltoztatására irányuló bírósági eljárás megindításához elengedhetetlenül szükséges. /Gyvt. 136.§/A/</p>
      <p>A gyermekjóléti szolgáltatást nyújtó szolgáltató és a gyámhatóság a gyermek bántalmazása, elhanyagolása miatt jelzést vagy kezdeményezést tevő intézmény, személy adatait erre irányuló külön kérelem hiányában is zártan kezeli. /Gyvt. 17.§ (2a)/</p>
    </ul>
    <ul>
      <strong id="helyesbites-jog">A helyesbítéshez való jog</strong>
      <p>
      Az érintett személy az I. pontban megadott elérhetőségeken keresztül, írásban kérheti, hogy  az Intézmény módosítsa valamely személyes adatát.
      <br/>
      Intézmény a  kérelmet  legfeljebb egy  hónapon  belül  teljesíti,  és erről az általa megadott elérhetőségre küldött levélben értesíti.</p>
    </ul>
    <ul>
      <strong id="torles-jog">A törléshez való jog</strong>
      <p>
      Az érintett személy az I. pontban megadott elérhetőségeken keresztül, írásban kérheti az Intézménytől a személyes adatainak a törlését.
      <br/>
      A törlési kérelmet az Intézmény abban az esetben utasítja el, ha az Intézményt a személyes adatok további tárolására  kötelezi.  Ilyen eset például az, ha az irattárazásra vonatkozó határidő nem telt le.  Amennyiben azonban nincs ilyen kötelezettség, akkor az Intézmény a kérelmet legfeljebb egy hónapon belül teljesíti, és erről az e célból megadott elérhetőségre  küldött  levélben  értesíti  az érintettet.
      </p>
    </ul>
    <ul>
      <strong id="zarolas-jog" style="width: 25em">A zároláshoz (adatkezelés korlátozásához) való jog</strong>
      <p>Az  érintett  személy  az  I.  pontban  megadott  elérhetőségeken  keresztül,  írásban  kérheti,  hogy  a személyes adatait az Intézmény zárolja (az adatkezelés korlátozott jellegének egyértelmű jelölésével és  az  egyéb  adatoktól  elkülönített  kezelés  biztosításával).  A zárolás addig tart, amíg az érintett által megjelölt indok szükségessé teszi az adatok tárolását. Az adat zárolását kérheti az érintett például  abban  az  esetben,  ha  úgy  gondolja,  hogy  a beadványát  az  Intézmény  jogellenesen  kezelte,  azonban az  általa  kezdeményezett  hatósági  vagy bírósági  eljárás  érdekében  szükséges  az,  hogy  a  beadványt  az  Intézmény  ne  törölje.  Ebben az esetben a hatóság vagy a bíróság  megkereséséig  az  Intézmény  tovább  tárolja  a  személyes  adatot (például az adott beadványt), ezt követően törli az adatokat. </p> 
    </ul>
    <ul>
      <strong id="tiltakozas-jog">A tiltakozáshoz való jog</strong>
      <p>Az érintett személy az I. pontban megadott elérhetőségeken keresztül, írásban tiltakozhat az adatkezelés ellen, ha az Intézmény személyes adatot közvélemény-kutatás vagy tudományos kutatás céljából továbbítaná, felhasználná. Így például tiltakozhat az ellen, ha az Intézmény hozzájárulása nélkül a személyes adatot tudományos kutatás céljából felhasználná.</p> 
    </ul>
    <ul>
      <strong id="adathord-jog">Az adathordozhatósághoz való jog</strong>
      <p>Az érintett jogosult arra, hogy a rá vonatkozó, általa az adatkezelő rendelkezésére bocsátott személyes adatokat tagolt, széles körben használt, géppel olvasható formátumban megkapja, továbbá jogosult arra, hogy ezeket az adatokat egy másik adatkezelőnek továbbítsa.</p> 
    </ul>
    <ul>
      <strong id="hozzajarulas-jog">Hozzájárulás visszavonásának joga</strong>
      <p>A hozzájárulás bármikor visszavonható, a visszavonás nem érinti a visszavonás előtt a hozzájárulás alapján végrehajtott adatkezelés jogszerűségét.</p> 
      <p>Felhívjuk szíves figyelmét, hogy amennyiben az Ön kilétével kapcsolatban kétségünk merül fel, abban az esetben a személyazonosságának megerősítéséhez, kilétének megállapításához szükséges információk nyújtását kérhetjük.</p>
    </ul>
    <ul>
      <strong id="elszamoltat-jog">Elszámoltathatósághoz való jog</strong>
      <p>Az érintett jogosult arra, hogy a rá vonatkozó, általa az adatkezelő rendelkezésére bocsátott személyes adatokat tagolt, széles körben használt, géppel olvasható formátumban megkapja, továbbá jogosult arra, hogy ezeket az adatokat egy másik adatkezelőnek továbbítsa.</p> 
    </ul>
    <strong id="jogorvoslat">Jogorvoslati lehetőségek</strong>
    <ul>      
      <p>Személyes adatai kezelésével kapcsolatos panaszával kérjük, keresse az intézmény adatvédelmi tisztviselőjét. Elérhetőségeiért kattintson ide.  Az Intézmény a panaszát kivizsgálja, és tájékoztatja a vizsgálat eredményéről és az általa megtett intézkedésekről.</p> 
      <p>Amennyiben az Ön megítélése szerint személyes adatainak kezelése sérti a vonatkozó jogszabályokat, jogosult az adatvédelmi felügyeleti hatóságnál panaszt tenni vagy bírósághoz fordulni.</p>
      <p>Magyarországon az adatvédelmi felügyeleti hatóság: Nemzeti Adatvédelmi és Információszabadság Hatóság (1125 Budapest, Szilágyi Erzsébet fasor 22/C) <a href="mailto:ugyfelszolgalat@naih.hu">ugyfelszolgalat@naih.hu</a>.</p>
    </ul>
    <strong id="adatkez-szabalyok" style="width: 50em">Az intézmény adatkezelését érintő jogszabályok rövidített jegyzéke</strong>
    <ul>
      <p>a.)	a természetes személyeknek a személyes adatok kezelése tekintetében történő védelméről és az ilyen adatok szabad áramlásáról, valamint a 95/46/EK rendelet hatályon kívül helyezéséről szóló az Európai Parlament és a Tanács 2016/679 rendelete (Általános adatvédelmi rendelet)</p>
      <p>b.)	az információs önrendelkezési jogról és az információ szabadságról szóló 2011. évi CXII. törvényben (továbbiakban: Infotv.)</p>
      <p>c.)	a gyermekek védelméről és a gyámügyi igazgatásról szóló 1997. évi XXXI. törvény (továbbiakban: Gyvt.), </p>
      <p>d.)	a szociális igazgatásról és szociális ellátásokról szóló 1993. évi III. törvény (továbbiakban: Szt.), </p>
      <p>e.)	a személyes gondoskodást nyújtó gyermekjóléti, gyermekvédelmi intézmények, személyek szakmai feladatairól és működésük feltételeiről szóló 15/1998. (IV. 30.) NM (továbbiakban: NMr.)</p>
      <p>f.)	a gyámhatóságok, a területi gyermekvédelmi szakszolgálatok, a gyermekjóléti szolgálatok és a személyes gondoskodást nyújtó szervek és személyek által kezelt személyes adatokról szóló  235/1997. (XII. 17.) Korm. rendelet</p>
      <p>g.)	a személyes gondoskodást nyújtó szociális intézmények szakmai feladatairól és működésük feltételeiről szóló 1/2000. (I. 7.) SzCsM rendelet</p>
    </ul>
    <strong id="int-szabalyozok">Intézményi szabályozók</strong>
    <ul>
      <li><a href="../../../assets/Belső adatvédelmi szabályzat 2022-2023.pdf">Belső adatvédelmi és adatbiztonsági szabályzat</a></li>
      <li><a href="../../../assets/adatkezelési tevékenységek nyilvántartása.pdf">Adatkezelési tevékenységek nyilvántartása</a></li>
    </ul>
    <strong id="adatbiztonsagi-intezk-osszefogl">Adatbiztonság</strong>
    <p>A részletszabályok a Belső adatvédelmi és adatbiztonsági szabályzatban érhetőek el <a href="../../../assets/Belső adatvédelmi és adatbiztonsági szabályzat.pdf">ide</a> kattintva.</p>
    <ul>
      <strong id="adatbiztonsagi-alapelvek">Az adatbiztonság alapelvei</strong>
      <ul>
        <li>Bizalmasság elve</li>
        <p>Az adatok bizalmasságának megvédése, annak garanciája, hogy az adatokhoz jogosulatlanul vagy illetéktelenül nem juthatnak hozzá.</p>
        <li>Sértetlenség elve</li>
        <p>Az adatok sértetlensége (integritása) azt jelenti, hogy azokat csak az arra jogosultak változtathatják meg.</p>
        <li>Rendelkezésre állás elve</li>
        <p>Annak a biztosítása, hogy az adatok mindig elérhetőek legyenek, jogtalanul ne semmisítsék meg, ne töröljék azokat.</p>
      </ul>
      <strong id="beleptetes-megfigyeles" style="width: 30em">Beléptetés és elektronikus megfigyelési rendszer</strong>
      <p>Az érintettek az intézmény területére történő belépéssel elismerik és tudomásul veszik a kamerás megfigyelés tényét. A megfigyelő rendszer létéről és működéséről a kötelezettségének megfelelve az intézmény figyelemfelhívó jelzést helyez el arról, hogy az adott területen elektronikus megfigyelőrendszert alkalmaz. A figyelemfelhívó jelzés mellett az adatvédelmi tájékoztató is kihelyezésre került. A tájékoztató <a href="../../../assets/Elektronikus megfigyelési rendszerrel kapcsolatos adatkezelési tájékoztató (19M).pdf">itt</a> érhető el.</p>
    </ul>
</div>
