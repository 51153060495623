import { Component, OnInit , Input} from '@angular/core';

@Component({
  selector: 'app-subpage-navigator',
  templateUrl: './subpage-navigator.component.html',
  styleUrls: ['./subpage-navigator.component.css']
})
export class SubpageNavigatorComponent implements OnInit {

  @Input() currentPageTitle = "";

  constructor() { }

  ngOnInit() {

  }

}
