import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { IsmertetoComponent } from './components/ismerteto/ismerteto.component';
import { AktualitasokComponent } from './components/aktualitasok/aktualitasok.component';
import { ElerhetosegekComponent } from './components/elerhetosegek/elerhetosegek.component';
import { GyejoSzolgIsmertetoComponent } from './components/gyejo-szolg-ismerteto/gyejo-szolg-ismerteto.component';
import { GyejoKozpontIsmertetoComponent } from './components/gyejo-kozpont-ismerteto/gyejo-kozpont-ismerteto.component';
import { SzocSzolgIsmertetoComponent } from './components/szoc-szolg-ismerteto/szoc-szolg-ismerteto.component';
import { KozSzolgIsmertetoComponent } from './components/koz-szolg-ismerteto/koz-szolg-ismerteto.component';
import { AdatvedelemComponent } from './components/adatvedelem/adatvedelem.component';
import { GazdAdatokComponent } from './components/gazd-adatok/gazd-adatok.component';

const routes: Routes = [
  {
    path: '',
    component: AktualitasokComponent
  },

  {
    path: 'ismerteto',
    component: IsmertetoComponent
  },

  {
    path: 'elerhetosegek',
    component: ElerhetosegekComponent
  },

  {
    path: 'gyejo_szolg_imerteto',
    component: GyejoSzolgIsmertetoComponent
  },

  {
    path: 'gyejo_kozpont_imerteto',
    component: GyejoKozpontIsmertetoComponent
  },

  {
    path: 'szoc_szolg_imerteto',
    component: SzocSzolgIsmertetoComponent
  },

  {
    path: 'adatvedelem',
    component: AdatvedelemComponent
  },

  {
    path: 'koz_szolg_imerteto',
    component: KozSzolgIsmertetoComponent
  },

  {
    path: 'gazd_adatok',
    component: GazdAdatokComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    enableTracing: false
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
