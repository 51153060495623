<app-subpage-navigator currentPageTitle="Család -és gyermekjóléti szolgálat"></app-subpage-navigator>
<div class="text-container">
  <p style="padding: 0em 4em 0em 4em">
      A családsegítés és gyermekjóléti szolgáltatás a feladatait tájékoztatás nyújtásával, szociális segítőmunkával, ezen belül a személyes gondoskodást nyújtó szociális intézmények szakmai feladatairól és működésük feltételeiről szóló 1/2000. (I.7.) SZCSM rendelet 2. § l) pont lb) alpontja szerinti esetkezeléssel, valamint más személy, illetve szervezet által nyújtott szolgáltatások, ellátások közvetítésével látja el.
  </p>

  <h4>A Szolgálat ellátja az Szt. 64.§ (4) bekezdése szerinti családsegítést a következők szerint: </h4>
  <ul>
    <li>A jogszabályban foglaltak szerinti családgondozás, szociális és mentálhigiénés problémák esetén az életvezetési nehézségek elhárítása és feloldása, továbbá a helyi szociális szükségletek feltárásának és megoldásának elősegítése. </li>
    <li>A családsegítő szolgáltatás általános és speciális jellegű segítségnyújtás a szociális és mentálhigiénés problémái vagy krízishelyzete miatt segítséget igénylő személynek, családnak, közösségnek, a krízishez vezető okok megelőzése és megszüntetése, valamint az életvezetési képesség megőrzése céljából. Az Intézmény feladata továbbá a családon belüli erőszak témakörével való kiemelt foglalkozás, a családokon belüli kapcsolaterősítést szolgáló közösségépítő, konfliktuskezelő programok és szolgáltatások biztosítása, a pszichológiai tanácsadás. </li>
  </ul>

  <h4>A Szolgálat feladatai a családsegítés területén különösen a következők:</h4>
  <ul>
      <li>a családi szociális feszültségek okainak feltárása, megoldásokra javaslat készítése, </li>
      <li>életvezetési (pl. nevelési, pszichológiai, egészségügyi) tanácsadás vagy ennek megszervezése,</li>
      <li>a családi kapcsolatok javítása, </li>
      <li>egyéni-, pár- és családterápiás tevékenység végzése, megszervezése,</li>
      <li>krízishelyzetben segítő beavatkozás, </li>
      <li>speciális támogató, önsegítő csoportok megszervezésének, működtetésének segítése, </li>
      <li>természetbeni, anyagi és személyes támogatások közvetítése,</li>
      <li>hivatalos ügyek intézésének segítése, </li>
      <li>együttműködés más intézményekkel, társadalmi szervezetekkel, csoportokkal,</li>
      <li>ingyenes jogi tanácsadás, a menedékjogi törvényben meghatározott integrációs szerződésbe foglalt társadalmi beilleszkedés elősegítésének biztosítása,</li>
      <li>a hozzátartozók közötti erőszak miatt alkalmazható távoltartásról szóló 2009. LXXII törvény értelmében a hozzátartozók közötti erőszak megelőzését szolgáló és a jelzési kötelezettség a bűncselekmények sértettjeinek és hozzátartozóinak, különös tekintettel a családon belüli erőszak áldozatainak és hozzátartozóinak a családsegítői tevékenység mellett szolgáltatások nyújtása vagy közvetítése.</li>
    </ul>

    <h4>A családsegítő szolgáltatás keretében az intézmény:</h4>
    <ul>
      <li>szociális, életvezetési és mentálhigiénés tanácsadást nyújt,</li>
      <li>megszervezi az anyagi nehézségekkel küzdők számára a pénzbeli, természetbeni ellátásokhoz, továbbá a szociális szolgáltatásokhoz való hozzájutást,</li>
      <li>folyamatosan működteti a családok segítése érdekében a veszélyeztetettséget, illetve krízishelyzetet észlelő jelzőrendszert,</li>
      <li>családgondozást végez, így elősegíti a családban jelentkező működési zavarok, illetve konfliktusok megoldását,</li>
      <li>közösségfejlesztő programokat valamint egyéni és csoportos készségfejlesztést szervez,</li>
      <li>tanácsadást nyújt a tartós munkanélküliek, a fiatal munkanélküliek, az adósságterhekkel és lakhatási problémákkal küzdők, a fogyatékossággal élők, a krónikus betegek, a szenvedélybetegek, a pszichiátriai betegek, a kábítószer-problémával küzdők, illetve egyéb szociálisan rászorult személyek és családtagjaik részére,</li>
      <li>kríziskezelést végez, valamint a nehéz élethelyzetben élő családoknak segítő szolgáltatásokat nyújt.</li>
    </ul>

    <p style="padding: 0em 4em 0em 4em">
        A Szolgálat ellátja a Gyvt. 40. §-a szerinti <span class="bold-mark">gyermekjóléti szolgáltatás</span> feladatait a következők szerint:  
        A gyermekjóléti szolgáltatás biztosítja a gyermek testi és lelki egészségének, családban történő nevelkedésének elősegítését, a gyermek veszélyeztetettségének megelőzését, a kialakult veszélyeztetettség megszüntetését, illetve a családjából kiemelt gyermek visszahelyezését. Ennek érdekében a Szolgálat családgondozást illetve további, gyermekjóléti szolgáltatásokat biztosít. Feladata a családon belüli erőszak gyermekáldozataival való kiemelt foglalkozás, gyermekpszichológiai tanácsadás, fejlesztőpedagógiai tanácsadás. 
    </p>

    <h3>A Szolgálat feladatai a gyermekjóléti szolgáltatás területén különösen a következők:</h3>
    <h4>A gyermek testi, lelki egészségének, családban történő nevelésének elősegítése érdekében: </h4>
    <ul>
      <li>tanácsot, tájékoztatást ad, segítséget nyújt a szükséges támogatásokhoz való hozzájutáshoz,</li>
      <li>szervezi a családtervezési, a pszichológiai, a nevelési, az egészségügyi, a mentálhigiénés és a káros szenvedélyek megelőzését célzó ellátásokhoz való hozzájutást,</li>
      <li>támogatja a válsághelyzetben lévő várandós anyát, segíti szociális szolgáltatásokhoz és gyermekjóléti alapellátásokhoz, különösen a családok átmeneti otthonában igénybe vehető ellátáshoz jutását,</li>
      <li>szabadidős programokat szervez,</li>
      <li>hivatalos ügyek intézéséhez nyújt segítséget.</li>
    </ul>

    <h4>A gyermek veszélyeztetettségének megelőzése érdekében: </h4>
    <ul>
      <li>veszélyeztetettséget észlelő és jelző rendszert működtet, valamint elősegíti magánszemélyek részvételét a megelőző rendszerben, </li>
      <li>a jogszabályban meghatározott személyekkel és intézményekkel való együttműködés megszervezése, tevékenységük összehangolása, </li>
      <li>javaslat készítése a veszélyeztetettséget előidéző okok feltárása és ezek megoldására,</li>
      <li>tájékoztatást nyújt az intézményeknél működő inkubátorokból, illetve abba a gyermek örökbefogadáshoz való hozzájárulás szándékával történő elhelyezésének lehetőségéről,</li>
      <li>a gyermek veszélyeztetettségének megelőzése érdekében iskolai szociális munkát biztosíthat.</li>
    </ul>

    <h4>A kialakult veszélyeztetettség megszüntetése érdekében:</h4>
    <ul>
      <li>a gyermekkel és családjával végzett szociális munkával elősegíti a gyermek problémáinak rendezését, a családban jelentkező működési zavarok ellensúlyozását,</li>
      <li>elősegíti a családi konfliktusok megoldását különösen a válás, a gyermekelhelyezés és a kapcsolattartás esetében, </li>
      <li>kezdeményezni egyéb gyermekjóléti, szociális és egészségügyi alapellátások valamint pedagógiai szakszolgálatok igénybevételét.</li>
    </ul>
  </div>