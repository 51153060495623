<app-subpage-navigator currentPageTitle="Közösségi szolgálat"></app-subpage-navigator>
<div class="text-container">
  <p class="text-indent">
      A szakmai egységben folyó ellátás olyan önkéntesen igénybe vehető, hosszú távú, közösségi alapú gondozás, amelynek keretében a gondozás és a pszicho-szociális rehabilitáció a kliens otthonában, illetve lakókörnyezetében valósul meg. 
  </p>

  <h4>A közösségi ellátás az alábbi szolgáltatási elemeket biztosítja: </h4>
  <ul>
    <li>tanácsadás</li>
    <li>esetkezelés</li>
    <li>készségfejlesztés</li>
    <li>mentális gondozás</li>
    <li>megkeresés</li>
  </ul>  
</div>